import { FC, useMemo } from 'react';
import ActionPreview from '../../ActionPreview';
import ActionBaseProps from '../ActionBaseProps';

const DescriptionPreview: FC<ActionBaseProps<never>> = (props) => {
  const { data } = props;
  const { question, previewQuestion, previewDescription, titleStyle } = data;

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  return <ActionPreview data-cy="description-preview" question={title || ''} description={previewDescription} titleStyle={titleStyle} />;
};

export default DescriptionPreview;
